@keyframes fillIn{
    from{
        width: 0%
    }
    to{
        width: 100%
    }
}
.mynavbar{
    width: 20%;
    height: 100vh;
    /* background: #d1c4e991; */
    background-color: #3c6ee0;
    /* padding-right: 10px; */
    box-shadow: 0 6px 0 0 rgb(0 0 0 / 1%), 0 15px 32px 0 rgb(0 0 0 / 6%);
    justify-content: center;
    align-items: center;
}
.search-icon{
    color: rgb(92, 107, 192);
    position: absolute;
    left: 20%;
    border-top-left-radius: 100px;
    top: 0%;
    background: #c4cce991;
    border-bottom-left-radius: 100px;
    padding: 8px;
}
.search-icon:hover{
    cursor:default
}
.logo{
    width: 100%;
}
.divider{
    border-top: 1px solid #bdbdc75d;
    margin-top: 1.32rem;
    margin-bottom: 1rem;
    height: 2px;
    width: 100%;
}
.menu{
    /* width: 100%; */
    height: 45px;
    margin-top: 5%;
    align-items: center;
    transition: 0.06s;
    color: none !important;
    text-decoration: none !important;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
    transition: 0.4s;
    border-radius: 100px;
}
.menu > p,.dividerContain > p{
    margin-top: 16px;
    margin-bottom: 16px;
}
.menu:hover{
    color: #5c6ec0 !important;
}
.menu.active{

    background-color: white;
    border: none;
    cursor: pointer;
    color: #5c6ec0 !important;
}
.menu:hover{
    background-color: #b6bee07e;
}
.menu:hover > p ,.menu.active > p{
    color: #5c6bc0 !important;
}
.menu:hover > i ,.menu.active > i{
    color: #5C6BC0 !important;
}
.sep-text{
    margin-right: 10px;
    margin-left: 10px;
    color: rgba(189, 189, 199, 0.365);
    font-size: 15px;
}
.mobilecontrol{
    display: none;
}
.customBtn{
    width:25%;
    padding:3px;
    background-color:#c4cce991;
    border-radius:100px;
    margin:auto;
    color: rgb(92, 107, 192);
    transition: 0.3s;
}
.customBtn:hover{
    cursor: pointer;
    background-color:#c4c6e9;
    transform: scale(1.05);
    color: rgb(35, 64, 226);
}
.customBtn > h6{
    cursor: pointer;
}
.menuPill{
    margin-right:1%;
    /* background-color:#ffffffe0; */
    /* border-radius:8px; */
    border-radius:100px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    padding-left:16px !important;
    padding-right:16px !important;
    padding:8px;
    justify-content:center;
    align-content:center;
    align-items:center;
    background-color:#c4cce991;
    transition: 0.08s;
}
.menuPillTop{
    margin-right:1%;
    /* background-color:#ffffffe0; */
    border-radius:8px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    padding-left:11px;
    padding-right:11px;
    padding:5px;
    justify-content:center;
    align-content:center;
    align-items:center;
    background-color:#d1c4e991;
    transition: 0.08s;
}
.menuPillTop:last-child{
    margin-right: 15%;
}
.menuPill:hover, .menuPill.active, .menuPillTop:hover, .menuPillTop.active{
    background-color:#a4c2ec;
    cursor: pointer;
}
.contactCard{
    /* background-color: rgb(209 196 233 / 15%); */
    background-color: rgb(196 206 233 / 15%);
    border-radius: 8px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    padding:10px;
    transition: 0.08s
}
.contactCard:hover{
    background-color: rgb(196, 203, 233);
    cursor: pointer;
}
/* .customCol:last-child{
   margin-bottom: 50% !important;
} */
.ellipsed{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
}
.ellipsedTitle{
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
    overflow: hidden;
}
.customCard{
    width:100%;
    margin:auto;
    background-color:#ffffffe0;
    border-radius:24px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    padding:15px;
    transition:0.4s;
    margin-top: 0%;
}
.appCard{
    justify-content: start;
    align-items: center;
    border: 3px solid whitesmoke;
    width: 19%;
    padding: 10px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    margin-right: 1%;
    margin-bottom: 1%;
}
.appCard:hover{
    cursor: pointer;
    background-color: #b6bee07e;
}
.assistantCard{
    width:100%;
    height: 35%;
    margin:auto;
    background-color:#ffffffe0;
    border-radius:24px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    padding:15px;
    transition:0.4s;
    margin-top: 0%;
}
.assistantCard.active{
    width:100%;
    height: 90%;
    margin-top: 5%;
    overflow-y: auto;
    
}
.customBox{
    border:3px solid whitesmoke;
    padding:10px;
    height:100%;
    margin-right:10px;
    margin-bottom:10px;
    justify-content:'center';
    align-items:'center';
    flex-direction: row;
    margin: auto;
    transition: 0.4s;
}
.customBox:hover{
    background-color: rgba(196, 205, 233, 0.4);
    cursor: pointer;
}
.selectBox{
    border:3px solid whitesmoke;
    padding:10px;
    height:100%;
    margin-right:10px;
    margin-bottom:10px;
    min-height:75px;
    justify-content:'center';
    align-items:'center';
}
.selectBox:hover{
    cursor: pointer;
    background-color: #c5b6e0;
    border-color: #c5b6e0;
    color: #5C6BC0;
}
.custom-modal{
    padding:25px;
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
    border: none;
}
#profileModal{
    border: none;
    z-index: 999999;
}
.headerIcons{
    color: rgb(92 117 192);
    font-size: 20px;
    background: rgb(196 204 233 / 40%);
    padding: 10px;
    border-radius: 100px;
    transition: 0.08s;
}
.headerIcons:hover{
    cursor: pointer;
    color: rgb(66, 84, 187);;
    background: rgb(196, 200, 233) !important;
}
.searchCustom{
    border:none;
    width: 60%;
    border-radius: 100px;
    padding: 8px;
    box-shadow: 0px 4px 12px -37px rgba(0,0,0,0.1);
}
.searchCustom:focus{
    border-color: rgb(209 196 233 / 15%) !important;
}
.closeBtn:hover{
    cursor: pointer;
    color: rgb(39, 63, 204) !important;
}

.skeleton {
    padding:15px;
    max-width: 300px;
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
}
.skeleton.square {
    height: 80px;
    border-radius: 5px;
    background: rgba(130, 130, 130, 0.2);
    /* background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2))); */
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 800px 100px;
    animation: wave-squares 2s infinite ease-out;
}
.backBtn{
    background-color: rgba(197, 196, 233, 0.15);
    margin: auto;
    width: 50px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    top: 5%;
    left: 3%;
    z-index: 999999;
    /* margin-left: 15px; */
}
.backBtn:hover{
    background-color: rgb(196, 197, 233);
    cursor: pointer;
}
.midContainer{
    width:65%;
    margin:auto
}
.pillsContainer{
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow-x: auto;
}
.menuIcons{
    position:absolute;
    left:20%;
    color: whitesmoke
}
@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
 @keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}
@media (max-width: 1025px) {
    .menu > i{
        margin-left: 0px !important;
    }
    .midContainer{
        width: 85%;
    }
    .appCard {
        width: 24%;
    }
    .menuIcons{
       display: none;
    }
}
@media (max-width: 745px) {
    .midContainer{
        width: 90%;
    }
    .appCard {
        width: 32%;
    }
    .sep-text{
        margin-left:8px;
    }
    .mynavbar{
        /* display: none; */
        width: 0%;
        transition: 0.4s;
        overflow: hidden;
    }
    .mynavbar.show{
        /* display:block; */
        position: absolute;
        width: 100%;
        background-color: #bea4ec;
        z-index: 98;
        padding-top: 5%;
    }
    .mCover{
        width: 33%;
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: unset;
    }
    .mobilecontrol{
        display: flex;
        flex-direction: row;
        background-color: #a4afec;
        width: 105%;
        height: 6%;
        position: fixed;
        z-index: 99;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        /* #090924 */
    }
    .mobile-menu{
        width: 50%;
        padding: 3px;
    }
    .mobile-menu.active{
        background-color: #5c6bc052;
        border-radius: 100px;
    }
    .searchCover{
        margin-top: 5%;
    }
}
@media (max-width: 475px) {
    .appCard {
        width: 49%;
    }
    .searchCover{
        margin-top: 15%;
    }
}